<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__header">
        <h3>Invoices</h3>
      </div>
      <div class="ps-section__content">
        <div class="table-responsive">
          <table class="table ps-table ps-table--invoices">
            <thead class="thead-light">
              <tr>
                <th>Reference</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td><router-link :to="{name: 'my_order', params: {id: order.id}}">{{ order.order_number }}</router-link></td>
                <td>{{ fomartDateApp(order.created_at) }}</td>
                <td>{{ order.total_amount }}</td>
                <td>
                  <span v-if="order.status.name === 'paid'" class="text-success">{{ order.status.label }}</span>
                  <span v-else class="text-danger">{{ order.status.label }}</span>
                </td>
                <td>
                  <button v-if="order.status.name === 'pending'" class="btn-info btn btn-sm mr-2">Cancel</button>
                  <router-link v-if="order.status.name === 'pending'" class="btn-primary btn btn-sm mr-2" :to="{name: 'payment', params: {id: order.id}}">Pay</router-link>
                  <router-link :to="{name: 'my_order', params: {id: order.id}}">View</router-link>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="meta.total === 0 && !isLoading">
                <td colspan="5" class="text-center m-4 text-danger">No orders available</td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="5" class="text-center m-4 text-danger">Loading orders...</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import UserSideNav from '@/components/mechantnavs/UserSideNav.vue';
import global from '@/mixins/global';
import axios from "axios";
import {mapState} from "vuex";

export default {
  mixins:[global],
  data() {
    return {
      isLoading: false,
      orders: [],
      meta: Object
    };
  },
  mounted() {
    this.getOrders();
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },
  methods: {
    getOrders() {
      this.isLoading = true;
      axios.get( "orders").then((response) => {
        this.orders = response.data.data;
        this.meta = response.data.meta;
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
      });
    },
  }
}
</script>

<style>
</style>